import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AssetMetadataUpdate from './apps/AssetUpdate';
import MasterfileUpload from './apps/MasterfileUpload';
import RenderDownload from './apps/RenderDownloadNew';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/assetUpdate" element={<AssetMetadataUpdate />} />
      <Route exact path="/renderDownload" element={<RenderDownload />} />
      <Route exact path="*" element={<MasterfileUpload />} />
    </Routes>
  </BrowserRouter>
);

export default App;
