import React, { Component, } from 'react';
import Select from 'react-select';
import '../App.css';
import "antd/dist/antd.css";
import {   message } from 'antd';


import {
  EMBED_PARENT_ORIGIN,
  APP_ENDPOINT,
  APP_ID,
  ORG_ID,
  TOKEN,
} from '../constants';


const appMeta = {
  orgId: ORG_ID,
  appid: APP_ID,
  cookie: TOKEN,
  threekitOrigin: EMBED_PARENT_ORIGIN,
};

function CompositeJobStatus(jobStatus) {
  if (jobStatus.jobID.totalTasks === jobStatus.jobID.successTasks) {
    return (
      <div>
        <h3>{`Composite Job Status: Ready to Download ${jobStatus.jobID.totalTasks} Image(s)`}</h3>
      </div>
    );
  } else if (jobStatus.jobID.invalidJobId) {
    return (
      <div>
        <h3>{`Invalid Job ID`}</h3>
      </div>
    );
  } else {
    return (
      <div>
        <h3>{`Composite Job Status: Pending (${jobStatus.jobID.successTasks}/${jobStatus.jobID.totalTasks})`}</h3>
      </div>
    );
  }
}
/*function CompTableRow(row){
  return (
    <tr><td><div>{row.JobName}</div></td></tr>
  )
}*/

const getProgress = (progress, idx, newData) => {
  const newProgress = [...progress];
  newProgress.splice(idx, 1, Object.assign(progress[idx], newData));
  return newProgress;
};


const RotationY_Horizontal = [{ value: 0, label: '0' },
{ value: 15, label: '15' },
{ value: 30, label: '30' },
{ value: 45, label: '45' },
{ value: 60, label: '60' },
{ value: 75, label: '75' },
{ value: 90, label: '90' },
{ value: 105, label: '105' },
{ value: 120, label: '120' },
{ value: 135, label: '135' },
{ value: 150, label: '150' },
{ value: 165, label: '165' },
{ value: 180, label: '180' },
{ value: 195, label: '195' },
{ value: 210, label: '210' },
{ value: 225, label: '225' },
{ value: 240, label: '240' },
{ value: 255, label: '255' },
{ value: 270, label: '270' },
{ value: 285, label: '285' },
{ value: 300, label: '300' },
{ value: 315, label: '315' },
{ value: 330, label: '330' },
{ value: 345, label: '345' }
];

const backgroundOptions = [{value:'None', label: 'None'},{value:'Black',label:'Black'}]
const RotationX_Vertical = [{ value: 0, label: '0' }, { value: -30, label: '-30' }]

var selectedProduct2 = "";
var selectedCol2 = "";
var selectedColVal2 = [];

var useBicolorSelected = false;

const defaultProducts = [];


class RenderDownload extends Component {
  state = {
    productsSet: false,
    productList: defaultProducts,
    productOptions: defaultProducts,
    selectedProduct: "",

    skuList: defaultProducts,
    skuOptions: defaultProducts,
    selectedSku: defaultProducts,


    colList: defaultProducts,
    colOptions: defaultProducts,
    selectedCol: defaultProducts,


    colValList: defaultProducts,
    colValOptions: defaultProducts,
    selectedColVal: defaultProducts,
    
    selectedBackground: {value:'None', label: 'None'},
    selectedRotationY: [{ value: 0 }],
    selectedRotationX: [{ value: 0 }],


    downloadJobID: '',
    downloadJobIDStatus: '',
    processingJobID:'',
    resetServer:false,


    loading: false,
    jobID: '',
    progress: [],
    totalCount: 0,
    curIdx: 0,

    compositeProgress: [],
    compositeCount: 0,
    curCompositeIdx: 0,

    compTable: [{JobName:'',JobID:'',ZipURL:''}],
    useBicolor: false,
    compTableSet:false,
  }


  //Get Configuration Options
  getProducts = () => {
    fetch(`${APP_ENDPOINT}/catalog/getProductList`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status !== 200) {
          window.alert(json.message);
        }
        this.setState({ productsSet: true });
        this.setState({ productList: json });
        const ProdOptions = json.map(({ product }) => ({ value: product.id, label: product.name }));
        this.setState({ productOptions: ProdOptions });
      });
  }

  getCols = () => {
    const {productOptions } = this.state;
console.log("get cols");
    console.log(productOptions);
    const thisProduct = productOptions.find((thisProd) => thisProd.value === selectedProduct2);

    fetch(`${APP_ENDPOINT}/catalog/getColumns`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        thisProduct,
        useBicolor:useBicolorSelected
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status !== 200) {
          window.alert(json.message);
        }
        console.log("get cols");
        //json.unshift("All");
        console.log(json);
        const colOptions = json.map((col) => ({ value: col, label: col }));
        console.log(colOptions);
        this.setState({ colOptions: colOptions });
        this.setState({ colList: json });
      });
  }

  getColVals = () => {
    const {productOptions } = this.state;
console.log("get skus");
    console.log(productOptions);
    const thisProduct = productOptions.find((thisProd) => thisProd.value === selectedProduct2);
    //const thisCol = colOptions.find((thisCol) => thisCol.value === selectedCol2);

    fetch(`${APP_ENDPOINT}/catalog/getColumnValues`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        thisProduct,
        useBicolor:useBicolorSelected,
        selectedColumn: selectedCol2
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status !== 200) {
          window.alert(json.message);
        }
        console.log("get col vals");
        //json.unshift("All");
        console.log(json);
        const colValOptions = json.map((colVal) => ({ value: colVal, label: colVal }));
        console.log(colValOptions);
        this.setState({ colValOptions: colValOptions });
        this.setState({ colValList: json });
      });
  }

  getSKUS = () => {
    const {productOptions } = this.state;
console.log("get skus start");
    console.log(productOptions);
    const thisProduct = productOptions.find((thisProd) => thisProd.value === selectedProduct2);

    fetch(`${APP_ENDPOINT}/catalog/getProductSKUS`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        thisProduct,
        useBicolor:useBicolorSelected,
        selectedColumn:selectedCol2,
        selectedColVal:selectedColVal2
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status !== 200) {
          window.alert(json.message);
        }
        console.log("get skus");
        //json.unshift("All");
        console.log(json);
        const skuOptions = json.map((sku) => ({ value: sku, label: sku }));
        console.log(skuOptions);
        this.setState({ skuOptions: skuOptions });
        this.setState({ skuList: json });
      });
  }

  //Process Configuration Changes
  onProductChange = (event) => {
    console.log("onProductChange");
    console.log(event);
    this.setState({ selectedProduct: event.value });
    this.setState({ useBicolor: false});
    this.setState({ selectedCol: [] });
    this.setState({ selectedColVal: [] });

    useBicolorSelected = false;
    selectedProduct2 = event.value;
    selectedCol2 = "";
    selectedColVal2 = []
    this.getSKUS();
    this.getCols();
  } 
  //Process Configuration Changes
  setBicolor = (event) => {
    console.log("setBicolor");
    console.log(event.target.checked);
    useBicolorSelected = event.target.checked;
    this.setState({ useBicolor: event.target.checked });
    this.setState({ selectedCol: "" });
    this.setState({ selectedColVal: [] });
    selectedCol2 = "";
    selectedColVal2 = []
    this.getSKUS();
    this.getCols();
  }

  onColChange = (event) => {
    selectedCol2 = event.value;
    selectedColVal2 = [];
    this.setState({ selectedCol: event.value });
    this.setState({ selectedColVal: [] });
    this.getSKUS();
    this.getColVals();
  }

  onColValChange = (event) => {
    selectedColVal2 = event.map(({value}) => value);
    console.log("selectedColVal2");
    console.log(selectedColVal2);
    this.setState({ selectedColVal: event });
    this.getSKUS();
  }

  onSkuChange = (event) => {
    this.setState({ selectedSku: event });
  }
  
  onBackgroundChange = (event) => {
    this.setState({ selectedBackground: event });
  }
  onRotationYChange = (event) => {
    this.setState({ selectedRotationY: event });
  }
  onRotationXChange = (event) => {
    this.setState({ selectedRotationX: event });
  }

  getJobStatus = () => {
    var jobID = "";
    if(document.getElementById('jobID')){
     jobID = document.getElementById('jobID').value.trim();
    }
    try {
      fetch(`${APP_ENDPOINT}/catalog/getJobStatus`, {
        method: 'POST',
        body: JSON.stringify({
          appMeta,
          jobID
        }),
        headers: { 'Content-Type': 'application/json' },
      }).then((res) => res.json())
        .then((json) => {
          if (json.status && json.status === 500) {
            window.alert(json.message);
          }
          if (json.tasks) {
            const totalTasks = json.tasks.length;
            const successTasks = json.tasks.filter(value => value.status === 'success').length;;
            const jobStatus = {
              totalTasks,
              successTasks,
              invalidJobId: false
            };
            this.setState({ downloadJobIDStatus: jobStatus });
          } else {
            const jobStatus = {
              totalTasks: 0,
              successTasks: -1,
              invalidJobId: true
            };
            this.setState({ downloadJobIDStatus: jobStatus });
          }
        });
    } catch (e) {
      const jobStatus = {
        totalTasks: 0,
        successTasks: -1
      };
      this.setState({ downloadJobIDStatus: jobStatus });
    }

  }

  getCompTable = () => {
    try {
    fetch(`${APP_ENDPOINT}/catalog/getCompTable`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => res.json())
      .then((json) => {
        if (json.status && json.status === 500) {
          window.alert(json.message);
        }
        console.log("COMP Resp",json);
        this.setState({ compTable: json});
        this.setState({compTableSet: true});
        
      });
  } catch (e) {
    
    this.setState({ compTable: [{JobName:'',JobID:'',ZipURL:''}] });
  }

  }

  downloadImageButton = async () => {

    const { downloadJobID, loading } = this.state;

    var jobID = document.getElementById('jobID').value.trim();
    console.log(jobID);
    if (!downloadJobID || loading) return;
    this.setState({ loading: true });

    let response, res;
    try {
      response = await fetch(`${APP_ENDPOINT}/catalog/getJobSize`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          appMeta,
          jobID
        }),
      });
      res = await response.json();
    } catch (e) {
      return message.error('Error initial download job:', e.toString());
    }

    console.log(res);
    if (response.status !== 200 || res.error) {
      return message.error(res.error);
    }

    if (res.running) {
      this.setState({ loading: false });
      this.setState({resetServer: true});

      return message.warning(
        `Server currently processing job ${res.jobId}, ${res.processed}/${res.total
        } processed. Please wait and try later or Reset Server State`
      );
    }

    const groupCount = 10;
    const totalCount = res.count;
    this.setState({ totalCount: totalCount });

    let groupIdx = 0;
    const progress = [];
    while (groupCount * groupIdx < totalCount) {
      const startFileIdx = groupCount * groupIdx;
      const groupTotalCount = Math.min(groupCount, totalCount - startFileIdx);
      const endFileIdx = startFileIdx + groupTotalCount;
      progress[groupIdx] = {
        status: 'awaiting',
        message: `Files ${startFileIdx + 1} ~ ${endFileIdx}`,
        data: res.tasks.slice(startFileIdx, endFileIdx),
        processed: 0,
      };
      groupIdx += 1;
    }

    this.setState({ progress });
    console.log(progress);

    for (let processedIdx = 0; processedIdx < progress.length; ++processedIdx) {
      console.log(processedIdx);
      await new Promise(async (resolve, reject) => {
        var newProgressState = { status: 'procressing' };
        const resolveWithError = () => {
          newProgressState.status = 'error';
          this.setState({
            progress: getProgress(progress, processedIdx, newProgressState),
          });
          resolve();
        };
        this.setState({
          progress: getProgress(progress, processedIdx, newProgressState),

          curIdx: processedIdx
        });

        //retrieve Job files          
        const retrieveJobRes = await fetch(`${APP_ENDPOINT}/catalog/retrieveJobFiles`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({ appMeta, jobID, data: progress[processedIdx].data }),
        }).then((res) => res.json());

        console.log(retrieveJobRes);
        if (retrieveJobRes.error) {
          return resolveWithError();
        }
        newProgressState = { status: 'complete' };
        this.setState({
          progress: getProgress(progress, processedIdx, newProgressState),
        });

        resolve();
      });
    }


    //retrieve Job files          
    fetch(`${APP_ENDPOINT}/catalog/downloadZip`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        jobID
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      return res.json(); 
    }).then(json => {
      try {
        const url = json.zipURL;
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = downloadJobID + ".zip";

        anchor.click();
      } catch (e) {
        console.log(e);
      }
    })

    /*fetch(`${APP_ENDPOINT}/catalog/downloadCompositeImages`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        jobID
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.status === 200) {
        return res.blob();
      }
      return null;
    }).then(blob => {
      try {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = downloadJobID + ".zip";

        anchor.click();
      } catch (e) {
        console.log(e);
      }
    });
  */
    this.setState({ loading: false });
    this.setState({compTableSet: false});
    this.setState({resetServer: false});
    this.getCompTable();
  }

/*
  getCompositePayload = () => {
    const { productList, selectedRotationX, selectedRotationY } = this.state;

    const product = productList.find(item => { return item.product.id === selectedProduct2 }).product;

    const compositePayload = {
      id: product.id,
      name: product.name,
      attributes: []
    }

    const attributes = [];
    var rotVal = 0;
    if (product.attributes.find(item => { return item.name === 'RotationY_Horizontal' })) {

      const rotYAttID = product.attributes.find(item => { return item.name === 'RotationY_Horizontal' }).id;
      rotVal = 0;
      if (selectedRotationY.value) { rotVal = selectedRotationY.value; }
      attributes.push({
        id: rotYAttID,
        name: "RotationY_Horizontal",
        values: [rotVal]
      });
    }
    if (product.attributes.find(item => { return item.name === 'RotationX_Vertical' })) {

      const rotXAttID = product.attributes.find(item => { return item.name === 'RotationX_Vertical' }).id;
      rotVal = 0;
      if (selectedRotationX.value) { rotVal = selectedRotationX.value; }
      attributes.push({
        id: rotXAttID,
        name: "RotationX_Vertical",
        values: [rotVal]
      });
    }
   
    compositePayload.attributes = attributes;
    return compositePayload;
    // var tempAssets = selectedcolor.map()

  }*/
  generateCompositeJob = async () => {

    this.setState({ loading: true });
    const {selectedBackground, selectedProduct,selectedSku, selectedRotationX, selectedRotationY,useBicolor,skuOptions} = this.state;
    //const compositePayload = this.getCompositePayload();
    const imageBackground = selectedBackground.value;
    //let skus = selectedSku.map((sku) => sku.value)
    /*if(skus.length() <= 0){
      skus = skuList;
    }*/
   let thisSelectedSKU = selectedSku;
   if(selectedSku.length === 0){
    thisSelectedSKU = skuOptions;
   }
    
    try {
      await fetch(`${APP_ENDPOINT}/catalog/startCompositeJob`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          appMeta,
          productId:selectedProduct,
          skus:thisSelectedSKU.map((sku) => sku.value),
          useBicolor,
          rotationX:selectedRotationX[0].value,
          rotationY:selectedRotationY[0].value,
          imageBackground
        }),
      })
      .then((res) => res.json())
      .then((json) => {
        if (json.status && json.status !== 200) {
          window.alert(json.message);
        }
        console.log(json);

        this.setState({jobID: json.jobId})
        this.setState({compTableSet: false});
        console.log(json.jobId)

      });
      
    } catch (e) {
      return message.error('Error initial download job:', e.toString());
    }



    this.setState({ loading: false });
    this.setState({compTableSet: false});
    //this.getCompTable();
    
    /*if (res.running) {

      return message.info(
        `Server currently process job ${res.jobName}, ${res.processed}/${res.total
        } processed! Please wait and try later!`
      );
    }


    //const jobName = res.jobName;

/*
    fetch(`${APP_ENDPOINT}/catalog/downloadCompositeZip`, {
      method: 'POST',
      body: JSON.stringify({
        appMeta,
        jobName
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res.status === 200) {
        return res.blob();
      }
      return null;
    }).then(blob => {
      try {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = jobName + ".zip";

        anchor.click();
      } catch (e) {
        console.log(e);
      }
    })*/
   
    //this.setState({ loading: false });
  }
 


  resetJobStatus = async () => {
    try {
      await fetch(`${APP_ENDPOINT}/resetServerStatus`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          appMeta
        }),
      });
    } catch (e) {
      return message.error('Error resetting  status:', e.toString());
    }
    return message.info(`Server state reset`);
  }
  render() {
    const { productsSet, productOptions,skuOptions,selectedSku, resetServer,
      downloadJobIDStatus, loading, progress, curIdx, totalCount,curCompositeIdx,compositeProgress,compositeCount,selectedProduct, useBicolor, compTable, compTableSet, jobID,
    colOptions, colValOptions } = this.state;
      console.log("productOptions:",this.state);
      console.log(compTable);
    if (!productsSet) {
      this.getProducts();
    }
    if(!compTableSet){
      this.getCompTable();
    }
    let showBicolor = false;
    console.log(selectedProduct);
    let selectedProductValue = productOptions.find(product => product.value === selectedProduct);
    if(selectedProductValue){
      console.log(selectedProductValue.label.includes('Lady Dior'));
      if((selectedProductValue.label.includes('Lady Dior') || selectedProductValue.label.includes('Lady D-joy')) && !selectedProductValue.label.includes('Haute')){
        showBicolor = true;
      }
      console.log(showBicolor);
    }

    //this.getJobStatus();
  
    return (
      <div className="App">
        <h1>Dior Render Download App</h1>
        <div className="GenerateComposite">
          <h2>Generate Composites</h2>
          <p>Product:</p><Select onChange={this.onProductChange} options={productOptions} />
          
          {showBicolor?<label><input type="checkbox" onChange={this.setBicolor} checked={useBicolor}/>Bicolor</label>:<div/>}


          {(selectedProduct2 !== "")?<div><p>Filter Column:</p> <Select onChange={this.onColChange} isMulti={false} options={colOptions}/></div>:<div/>}
          {(selectedCol2 !== "")?<div><p>Filter Values:</p> <Select onChange={this.onColValChange} isMulti={true} options={colValOptions}/></div>:<div/>}
          {(selectedProduct2 !== "")?<div><p>SKU:</p> <Select onChange={this.onSkuChange} isMulti={true} options={skuOptions}/></div>:<div/>}

          <p>RotationY_Horizontal:</p> <Select defaultValue={RotationY_Horizontal[0]} onChange={this.onRotationYChange} options={RotationY_Horizontal} />
          <p>RotationX_Vertical:</p> <Select defaultValue={RotationX_Vertical[0]} onChange={this.onRotationXChange} options={RotationX_Vertical} />
          
          {true?<div><p>
            Background:</p>
          <Select defaultValue={backgroundOptions[0]} onChange={this.onBackgroundChange} options={backgroundOptions} />
         </div>:<div/>}
       
            
          {(selectedProduct2 !== "" && selectedSku.length > 0)?<div><p>Number of Images: {selectedSku.length}</p> </div>:<div/>}
          {(selectedProduct2 !== "" && selectedSku.length === 0)?<div><p>Number of Images: {skuOptions.length}</p> </div>:<div/>}
          <div><p>
            <button disabled={loading} onClick={this.generateCompositeJob}>Generate Composite Job</button></p>

            {!!compositeProgress.length && (
            <div>{`Status: ${loading === true
              ? `Processing: ${Math.round((100*(curCompositeIdx*5)/compositeCount)*10)/10}%`
              : 'Complete'
              }`}</div>
          )}
          </div>
        </div>

        <div className="GenerateComposite">
          <h2>Download Renders from Job</h2>
          <h3>Job ID: <input defaultValue={jobID} id='jobID'
            onChange={(e) => this.setState({ downloadJobID: e.target.value })} /></h3>
          <button onClick={this.getJobStatus}>Check Job Status</button>
          {!!downloadJobIDStatus && (
            <CompositeJobStatus jobID={downloadJobIDStatus} />
          )}
          {!!downloadJobIDStatus && (
            <button disabled={loading} onClick={this.downloadImageButton}>Download Images</button>
          )}
          {resetServer?
          <button disabled={loading} onClick={this.resetJobStatus}>Reset Server State</button>
           :<div/>}

          {!!progress.length && (
            <p>{`Status: ${loading === true
              ? `Processing: ${curIdx * 10} / ${totalCount}`
              : 'Complete'
              }`}</p>
          )}


<div><table>
                <tr>
                    <th>Job Name</th>
                    <th>ID</th>
                    <th>Status</th>
                    <th>Zip Folder</th>
                </tr>
                {compTable.map((val, key) => {
                    return (
                        <tr key={key}>
                            <td><a href={val.JobID}>{val.JobName}</a></td>
                            <td>{val.JobId}</td>
                            <td>{val.Status}</td>
                            <td>{val.ZipURL !== '' && (<a href={val.ZipURL}>Download</a>)}</td>
                        </tr>
                    )
                })}
            </table></div>
        </div>
        

      </div>
    );

  }
}

export default RenderDownload;
